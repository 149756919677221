import React, { useEffect, useRef, useState } from "react";
import "./home.css";
import "./home-tablet.css";
import "./home-mobile.css";
import logo from "../../../assets/main_logo.png";
import logo_adobe from "../../../assets/logo_adobe.png";
import logo_facebook from "../../../assets/facebook_logo.png";
import logo_instagram from "../../../assets/instagram_logo.png";
import logo_rutart from "../../../assets/logo_rutart.png";
import banner2 from "../../../assets/banner2.png";
import banner3 from "../../../assets/banner3.png";
import QR_koda from "../../../assets/QR-koda.png";
import Puscica from "../../../assets/puscica.png";
import facebook_working from "../../../assets/facebook_working.png";
import instagram_working from "../../../assets/instagram_working.png";
import naroci_arrow_large from "../../../assets/blue_button_arrow_large.png";
import naroci_arrow_small from "../../../assets/blue_button_arrow_small.png";
import vprasaj_arrow_large from "../../../assets/red_button_arrow_large.png";
import vprasaj_arrow_small from "../../../assets/red_button_arrow_small.png";
import banner_0 from "../../../assets/gallery1/banner1.png";
import small_1 from "../../../assets/gallery1/gallery_small_1.jpg";
import small_2 from "../../../assets/gallery1/gallery_small_2.jpg";
import small_3 from "../../../assets/gallery1/gallery_small_3.jpg";
import small_4 from "../../../assets/gallery1/gallery_small_4.jpg";
import small_5 from "../../../assets/gallery1/gallery_small_5.jpg";
import small_6 from "../../../assets/gallery1/gallery_small_6.jpg";
import small_7 from "../../../assets/gallery1/gallery_small_7.jpg";
import small_8 from "../../../assets/gallery1/gallery_small_8.jpg";
import gallery2_1 from "../../../assets/gallery2/image1.jpg";
import gallery2_2 from "../../../assets/gallery2/image2.jpg";
import gallery2_3 from "../../../assets/gallery2/image3.jpg";
import gallery2_4 from "../../../assets/gallery2/image4.jpg";
import gallery2_5 from "../../../assets/gallery2/image5.jpg";
import gallery2_6 from "../../../assets/gallery2/image6.jpg";
import gallery2_7 from "../../../assets/gallery2/image7.jpg";
import gallery2_8 from "../../../assets/gallery2/image8.jpg";
import gallery2_9 from "../../../assets/gallery2/image9.jpg";
import gallery2_10 from "../../../assets/gallery2/image10.jpg";
import gallery2_11 from "../../../assets/gallery2/image11.jpg";
import gallery2_12 from "../../../assets/gallery2/image12.jpg";
import gallery2_13 from "../../../assets/gallery2/image13.jpg";
import gallery2_14 from "../../../assets/gallery2/image14.jpg";
import gallery2_15 from "../../../assets/gallery2/image15.jpg";
import gallery2_16 from "../../../assets/gallery2/image16.jpg";
import gallery2_17 from "../../../assets/gallery2/image17.jpg";
import gallery2_18 from "../../../assets/gallery2/image18.jpg";
import gallery2_19 from "../../../assets/gallery2/image19.jpg";
import gallery2_20 from "../../../assets/gallery2/image20.jpg";
import gallery2_21 from "../../../assets/gallery2/image21.jpg";
import gallery2_22 from "../../../assets/gallery2/image22.jpg";
import gallery2_23 from "../../../assets/gallery2/image23.jpg";

import CookieManager from "../../../components/CookieManager/CookieManager";
import ImageGallery from "../../../components/ImageGallery/ImageGallery";

const Home: React.FC = () => {
  const puscicaRef = useRef<HTMLImageElement | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  const imagesGallery1 = [
    banner_0,
    small_1,
    small_2,
    small_3,
    small_4,
    small_5,
    small_6,
    small_7,
    small_8,
  ];

  const imagesGallery2 = [
    gallery2_1,
    gallery2_2,
    gallery2_3,
    gallery2_4,
    gallery2_5,
    gallery2_6,
    gallery2_7,
    gallery2_8,
    gallery2_9,
    gallery2_10,
    gallery2_11,
    gallery2_12,
    gallery2_13,
    gallery2_14,
    gallery2_15,
    gallery2_16,
    gallery2_17,
    gallery2_18,
    gallery2_19,
    gallery2_20,
    gallery2_21,
    gallery2_22,
    gallery2_23,
  ];

  const openMailClient = (subject: string) => {
    const recipient = "oblikovanje@rutart.si";
    const body = "";
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const ScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Update the state initially based on current window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const btnNarociSeElement = document.querySelector(".button-naroci");

    if (btnNarociSeElement) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (puscicaRef.current) {
            if (entry.isIntersecting) {
              puscicaRef.current.classList.remove("visible");
            } else {
              puscicaRef.current.classList.add("visible");
            }
          }
        },
        { threshold: 0 }
      );

      observer.observe(btnNarociSeElement);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    const fbLogoImage = document.getElementById(
      "logo-facebook"
    ) as HTMLImageElement;
    const fbWorkingImage = document.getElementById(
      "working-facebook"
    ) as HTMLImageElement;

    const igLogoImage = document.getElementById(
      "logo-instagram"
    ) as HTMLImageElement;
    const igWorkingImage = document.getElementById(
      "working-instagram"
    ) as HTMLImageElement;

    const handleMouseOver = (workingImage: HTMLImageElement) => {
      workingImage.style.opacity = "1";
    };

    const handleMouseOut = (workingImage: HTMLImageElement) => {
      workingImage.style.opacity = "0";
    };

    const handleMobileClick = (workingImage: HTMLImageElement) => {
      handleMouseOver(workingImage);
      workingImage.style.zIndex = "10"; // Bring working image above the button

      setTimeout(() => {
        handleMouseOut(workingImage);
        workingImage.style.zIndex = "1"; // Restore z-index to make the button above again
      }, 3000); // Last for 3 seconds
    };

    if (fbLogoImage && fbWorkingImage && igLogoImage && igWorkingImage) {
      if (isMobile) {
        fbLogoImage.addEventListener("click", () =>
          handleMobileClick(fbWorkingImage)
        );
        fbLogoImage.addEventListener("mouseout", () =>
          handleMouseOut(fbWorkingImage)
        );

        igLogoImage.addEventListener("click", () =>
          handleMobileClick(igWorkingImage)
        );
        igLogoImage.addEventListener("mouseout", () =>
          handleMouseOut(igWorkingImage)
        );
      } else {
        fbLogoImage.addEventListener("mouseover", () =>
          handleMouseOver(fbWorkingImage)
        );
        fbLogoImage.addEventListener("mouseout", () =>
          handleMouseOut(fbWorkingImage)
        );

        igLogoImage.addEventListener("mouseover", () =>
          handleMouseOver(igWorkingImage)
        );
        igLogoImage.addEventListener("mouseout", () =>
          handleMouseOut(igWorkingImage)
        );
      }

      return () => {
        fbLogoImage.removeEventListener("click", () =>
          handleMobileClick(fbWorkingImage)
        );
        fbLogoImage.removeEventListener("mouseout", () =>
          handleMouseOut(fbWorkingImage)
        );
        fbLogoImage.removeEventListener("mouseover", () =>
          handleMouseOver(fbWorkingImage)
        );

        igLogoImage.removeEventListener("click", () =>
          handleMobileClick(igWorkingImage)
        );
        igLogoImage.removeEventListener("mouseout", () =>
          handleMouseOut(igWorkingImage)
        );
        igLogoImage.removeEventListener("mouseover", () =>
          handleMouseOver(igWorkingImage)
        );
      };
    }
  }, [isMobile]);

  return (
    <div className="home">
      <header>
        <div className="header-text">
          <h1>Oblikovanje</h1>
          <p>ZA DIGITALNI IN DRUŽBENI PROSTOR</p>
        </div>

        <img className="header-logo" src={logo} alt="Rutart main logo" />
        <p className="header-logo-text">Hitro oblikovanje na daljavo</p>
        <div className="header-line"></div>

        <button
          className="button-naroci"
          onClick={() => openMailClient("Za posvet")}
        >
          <p>Za posvet</p>
          {!isMobile ? (
            <img src={naroci_arrow_large} alt="Button naroci se" />
          ) : (
            <img src={naroci_arrow_small} alt="Button naroci se" />
          )}
        </button>
      </header>

      <main className="main">
        <section className="graficno-container">
          <div className="graficno">
            <h2>
              Grafično
              <br />
              oblikovanje
            </h2>
            <p>
              za posameznike, društva in podjetja. Skupaj najdimo nove
              razburljive načine komuniciranja vaše identitete v digitalnem in
              družbenem prostoru.
            </p>
          </div>
        </section>

        <section className="storitve-container">
          <div className="storitve">
            <h2>Storitve</h2>
            <p></p>
            <div className="list-container">
              <div className="list-item">
                <div className="list-item-content">
                  oblikovanje in urejanje spletnih strani
                </div>
              </div>
              <div className="list-item">
                <div className="list-item-content">
                  oblikovanje bannerjev za splet
                </div>
              </div>
              <div className="list-item">
                <div className="list-item-content">
                  oblikovanje in priprava video oglasov za družbena omrežja
                </div>
              </div>
              <div className="list-item">
                <div className="list-item-content">
                  grafično oblikovanje na daljavo
                </div>
              </div>
              <div className="list-item">
                <div className="list-item-content">
                  oblikovanje celostne grafične podobe
                </div>
              </div>
              <div className="list-item">
                <div className="list-item-content">oblikovanje logotipa</div>
              </div>
              <div className="list-item">
                <div className="list-item-content">
                  oblikovanje vizitk za tisk ali digitalne s QR-KODO
                </div>
              </div>
              <div className="list-item">
                <div className="list-item-content">
                  oblikovanje brošur, letakov, tiskovin
                </div>
              </div>
              <div className="list-item">
                <div className="list-item-content">oblikovanje embalaže</div>
              </div>
              <div className="list-item">
                <div className="list-item-content">
                  oblikovanje in prelom strani za knjige, kataloge, magazine ...
                </div>
              </div>
              <div className="list-item">
                <div className="list-item-content">
                  oblikovanje in priprava sejemskih in prireditvenih prostorov
                </div>
              </div>
              <div className="list-item">
                <div className="list-item-content">
                  oblikovanje majic po naročilu, personalizirane majice
                </div>
              </div>
            </div>
          </div>

          {isMobile ? (
            <div className="image-gallery-storitve">
              <ImageGallery images={imagesGallery1} />
            </div>
          ) : (
            <img
              className="storitve-banner"
              src={banner_0}
              alt="Rutart majca print"
            />
          )}
        </section>

        <section className="koristi-container">
          {isMobile ? (
            <div className="image-gallery-koristi">
              <ImageGallery images={imagesGallery2} />
            </div>
          ) : (
            <img
              className="koristi-banner"
              src={banner2}
              alt="Banner koristi"
            />
          )}
          <div className="koristi">
            <h2>Koristi</h2>
            <p>
              Na trgu, kjer je veliko podobnih izdelkov in storitev, lahko
              privlačna vizualna podoba ponudi konkurenčno prednost. Potrošniki
              so bolj nagnjeni k izbiri izdelka ali podjetja, ki jim vizualno
              ugaja.
            </p>
          </div>
        </section>

        <section className="izkusnje-container">
          <div className="izkusnje">
            <h2>
              Izkušnje
              <br />
              in strokovnost
            </h2>
            <p>
              Z več kot 15 leti izkušenj kot grafični in spletni oblikovalec v
              marketingu, kjer sem skrbel za celostno podobo podjetja, oblikoval
              promocijsko gradivo in upravljal spletno stran, zdaj kot
              samostojni podjetnik ponujam oblikovalske storitve za digitalne in
              tiskane medije.
              <br />
              Oblikoval sem plačilne kartice, zloženke, nagradne programe,
              priloge k računom, oglasne, spletne pasice, e-poštne kampanje in
              druge oglaševalske materiale. Za mednarodna podjetja sem zasnoval
              embalažo za kozmetične in prehrambene izdelke. Poleg tega sem
              fotografiral izdelke za naslovne vizuale, pripravljal izseke za
              tiskarje ter sodeloval pri projektih od zasnove do postavitve
              stojal in prodajnih artiklov v nakupovalnih središčih.
              <br />
              Kot samostojni podjetnik sem popolnoma prilagodljiv, kar pomeni,
              da lahko nudim oblikovalske storitve, ki so natančno prilagojene
              zahtevam in časovnim okvirom vašega podjetja. Z veseljem se bom
              pogovoril o vaših potrebah in pričakovanjih ter skupaj z vami
              poiskal najboljšo rešitev.
            </p>
            <img className="izkusnje-adobe" src={logo_adobe} alt="Adobe logo" />
          </div>

          <div className="izkusnje-banner-container">
            <img
              className="izkusnje-banner"
              src={banner3}
              alt="Banner izkusnje"
            />
            <div className="izkusnje-banner-text">
              Izberite
              <br />
              oblikovanje,
              <br />
              ki ustreza
              <br />
              vašim
              <br />
              potrebam
              <br />
              in ciljem!
            </div>
          </div>
        </section>

        <section className="reference-container">
          <div className="reference-title">
            <h2>
              Kaj drugi
              <br />
              pravijo o meni?
            </h2>

            <div className="reference">
              <div className="client">
                <h2>Dragana Čosič</h2>
                <h3>Vodja marketinga, Sparkasse Pay d.o.o.</h3>
                <p>
                  Sebastjan Rutart je izjemno profesionalen, dosleden in predan
                  delavec, s katerim imam izključno pozitivne izkušnje kot
                  njegova vodja od leta 2014. Vedno je zagotavljal najvišjo
                  kakovost predstavitve blagovne znamke in bil pripravljen
                  delati tudi izven delovnega časa. Njegova pomoč sodelavcem in
                  pripravljenost reševati težave sta bila neprecenljiva. Na
                  Sebastjana sem se vedno lahko zanesla zaradi njegove
                  poštenosti in zanesljivosti. Prepričana sem, da bo vsak
                  delodajalec z njim pridobil vestnega delavca, veliko znanja in
                  kreativnosti.
                </p>
              </div>
              <div className="client">
                <h2>Aljaž Podlogar</h2>
                <h3>Direktor in partner, Rubikon GRAL</h3>
                <p>
                  S Sebastjanom je bilo vedno profesionalno sodelovanje. Lahko
                  pohvalim odzivnost in natančnost. Doslednost pri grafičnih
                  podobah je bila vedno njegova vrlina. Izredno pa smo bili
                  zadovoljni tudi s kreativnim delom in spoštovanjem dogovorov.
                  Za piko na i pa bi dodal njegov humor, ki nam je pri odgovorih
                  vedno polepšal dan :).
                </p>
              </div>
              <div className="client">
                <h2>Mojca Javornik, prodajna managerka</h2>
                <h3>Spark Promotions, promocijska in poslovna darila</h3>
                <p>
                  S Sebastjanom sem večkrat sodelovala na projektih kot
                  izvajalka za tisk grafičnih materialov za Erste Card d.o.o.
                  Najpogosteje je šlo za tiskane brošure, zastavice, roll-upe,
                  etikete in podobne tiskane materiale, včasih pa tudi za druge
                  promocijske izdelke s tiskom.
                </p>
                <p>
                  Grafične podobe, ki jih je pripravil Sebastjan, so bile
                  natančno izdelane z vsemi opisi in detajli za izvedbo. Pri
                  njem se res pozna, da ima ogromno izkušenj, zato je njegov
                  odziv tudi pri dodatnih vprašanjih hiter in natančen. Na
                  Sebastjana se lahko zaneseš, da bo poznal specifiko izdelka,
                  ki ga je oblikoval, in da bo predlagal tudi ustrezne materiale
                  za tisk. Končni izdelki bodo zato izgledali točno tako, kot je
                  bilo mišljeno in dogovorjeno. Priporočam ga vsem, ki
                  potrebujete zanesljivega grafičnega oblikovalca z veliko
                  izkušnjami in široko mrežo poznanstev tudi na drugih
                  področjih, povezanih z marketingom. Komunikacija in
                  sodelovanje z njim mi je vedno v veselje.
                </p>
              </div>
              <div className="client">
                <h2>Anej Ferko</h2>
                <h3>CREATIVE TEAM</h3>
                <p>
                  Sebastjan je človek na katerega se res lahko zaneseš. Pri
                  svojem delu je profesionalen, ima občutek za delo s premium
                  blagovnimi znamkami in je pravi kreativec s srcem. Ko začuti
                  znamko zna iz nje narediti ogromno.
                </p>
              </div>
              <div className="client">
                <h2>Teja Ucman</h2>
                <p>
                  Sebastjan je mojster za tisk. S kreativnim pristopom zna
                  narediti res dobre ideje, ki presegajo okvir oblikovalca. In
                  tako lahko znamka izstopa.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="kontakt-container">
          <div className="kontakt">
            <h2>Kontakt:</h2>
            070 434 992
            <br />
            oblikovanje@rutart.si
            <br />
            rutart.si
            <br />
          </div>
          <img className="kontakt-qr" src={QR_koda} alt="QR code" />

          <button
            className="button-vprasaj"
            onClick={() => openMailClient("Želim portfolio")}
          >
            <p>Za portfolio</p>
            {isMobile ? (
              <img src={vprasaj_arrow_large} alt="Button ask for portfolio" />
            ) : (
              <img src={vprasaj_arrow_small} alt="Button ask for portfolio" />
            )}
          </button>
        </section>

        <section className="social">
          <div className="instagram">
            <img
              id="working-instagram"
              src={instagram_working}
              alt="Instagram working"
            />
            <img
              id="logo-instagram"
              src={logo_instagram}
              alt="Logo for Instagram"
            />
          </div>
          <div className="facebook">
            <img
              id="working-facebook"
              src={facebook_working}
              alt="Facebook working"
            />
            <img
              id="logo-facebook"
              src={logo_facebook}
              alt="Logo for Facebook"
            />
          </div>
        </section>

        <div className="footer-line" />
      </main>
      <footer className="footer">
        <div className="footer-left">
          <h3>Kontaktni podatki</h3>
          Sebastjan Rutart s.p.
          <br />
          Oblikovanje za digitalni in družbeni prostor
          <br />
          Mislinjska Dobrava 27, 2383, Šmartno pri Slovenj Gradcu
          <br />
          TRR: SI 56 34000-1022812402
          <br />
        </div>
        <div className="footer-right">
          <img
            className="footer-logo-rutart"
            src={logo_rutart}
            alt="Rutart logo"
          />{" "}
          <br />
          Sebastjan Rutart: Grafično oblikovanje
          <br />
          Tisk na majice po naročilu
          <br />
          Anže Fric: Postavitev in izdelava
          <br />
        </div>
        <img
          id="scroll-puscica"
          ref={puscicaRef}
          src={Puscica}
          alt="Scroll to top"
          onClick={ScrollToTop}
        />
      </footer>

      <CookieManager />
    </div>
  );
};

export default Home;
