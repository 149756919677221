import React, { useState, useEffect } from "react";
//import "./ImageGallery.css";
//import "./ImageGallery-tablet.css";
import "./ImageGallery-mobile.css";
import arrow_right from "../../assets/gallery_arrow_right.png";
import arrow_left from "../../assets/gallery_arrow_left.png";

interface ImageGalleryProps {
  images: string[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const nextImage = (images: string[]) => {
    setCurrentImage((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = (images: string[]) => {
    setCurrentImage((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="container">
      {currentImage !== 0 && (
        <img
          className="arrow left-arrow"
          src={arrow_left}
          alt="Previous"
          onClick={() => prevImage(images)}
        />
      )}
      <div
        className="gallery-images"
        style={{
          width: `calc(${100 * images.length}vw)`,
          transform: `translateX(-${currentImage * 100}vw)`,
        }}
      >
        {isMobile &&
          images.map((image, index) => (
            <img
              key={index}
              className="image"
              src={image}
              alt={`Gallery image ${index}`}
            />
          ))}
      </div>
      <img
        className="arrow right-arrow"
        src={arrow_right}
        alt="Next"
        onClick={() => nextImage(images)}
      />
    </div>
  );
};
export default ImageGallery;
